import { Box, CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { i18n } from "../../config";
import BateriaVirtualPercentageCard from "./BateriaVirtualPercentageCard";


/**
 * UI component
 * 
 * Responsible for rendering details related to the distribution of virtual battery percentages.
 * It also displays a loader while fetching percentage data.
 * 
 * - Delegate the rendering of percentage items to the `BateriaVirtualPercentageCard` component.
 */
const BateriaVirtualDistributedPercentageDetails = () => {
    const {
        view_contract
    } = useSelector((state) => state.contracts)
    const { 
        isFetchingBateriaVirtualPercentages,
        bateriaVirtualPercentages
    }  = useSelector((state) => state.bateriaVirtual);
    const drawedPercentageItems = bateriaVirtualPercentages?.map((data) => (
        <BateriaVirtualPercentageCard 
            key={data.polissa_id} 
            percentageData={data}
            active={data['polissa_id'] === view_contract['id']}
        />
    ));

    return (
        <Box>
            { isFetchingBateriaVirtualPercentages && <CircularProgress/>}
            { !isFetchingBateriaVirtualPercentages && <>
                <Typography variant="h6">{i18n.t('common:text.bateria_virtual_title')}:</Typography>
                <Paper style={{padding:15}} elevation={2}>
                    <Grid container spacing={2} direction="row">
                        { drawedPercentageItems }
                    </Grid>
                </Paper>
            </> }
        </Box>
    );
}

export default BateriaVirtualDistributedPercentageDetails;