import _ from "lodash";
import React from "react";
import PropTypes from 'prop-types';
import MultiUploadButton from "../MultiUploadButton";
import RichContent from "../RichContent";

import { ReactComponent as TelephoneIcon } from "./icons/telephone.svg";
import { ReactComponent as EmailIcon } from "./icons/email.svg";

import validate from "validate.js";
import { i18n } from "../../config";
import { dispatchNewRoute} from '../../utils/http_functions';
import Settings, { organization } from "../../settings";
import Phone from "phone";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/lead-contact";

import { Button, TextField, InputLabel,
  FormControl, Select, MenuItem, Grid, Typography,
  FormControlLabel, Checkbox, FormHelperText } from "@material-ui/core"
import {contraction_url} from '../../constants/i18n';

class LeadContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      surname1: "",
      surname2: "",
      email: "",
      telephone: "",
      contactHours: "",
      invoice: "",
      consent: false,
      errors: {},
    };
    this.contactHours = [
      { value: '9-13h', name: i18n.t('common:text.morning') + ' (9-13h)' },
      { value: '13-15h', name: i18n.t('common:text.noon') + ' (13-15h)' },
      { value: '15-19h', name: i18n.t('common:text.afternoon') + ' (15-19h)' },
      { value: '19-21h', name: i18n.t('common:text.evening') + ' (19-21h)' },
    ];

    validate.validators.presence.options = {
      message: `^${i18n.t('common:text.required_field')}`,
    };

    validate.validators.consent = function(value, options, key, attributes) {
      if (value) return null;
      else return `^${i18n.t('common:text.lead_contact_consent_required')}`;
    };

    validate.validators.leadContactPhone = (value, options, key, attributes) => {
      if (value) {
        const spanishFormattedValue = Phone(value, 'ESP', true);
        const foreignFormattedValue = Phone(value, '', true);
        if (_.isEmpty(spanishFormattedValue) && _.isEmpty(foreignFormattedValue)) {
          return '^' + i18n.t('common:text.lead_contact_phone_not_valid');
        } else {
          return null;
        }
      } else {
        return null;
      }
    };

    this.validations = {
      name: {
        presence: { allowEmpty: false },
      },
      surname1: {
        presence: { allowEmpty: false },
      },
      email: function(value, attributes, attributeName, options, constraints) {
        if (!props.emailRequired && attributes.telephone) {
          if (value) {
            return { email: true };
          } else {
            return null;
          }
        }
        return {
          presence: { allowEmpty: false },
          email: true,
        };
      },
      telephone: function(value, attributes, attributeName, options, constraints) {
        if (attributes.email) return null;
        return {
          presence: { allowEmpty: false },
          leadContactPhone: true,
        };
      },
      invoice: function(value, attributes, attributeName, options, constraints) {
        if (!props.attachInvoice) return null;
        return {
          presence: { allowEmpty: false }
        };
      },
      consent: {
        consent: true,
      },
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  validate = (attrs = null) => {
    let onlyValidate = {};
    if (attrs) {
      // eslint-disable-next-line no-return-assign
      Object.keys(attrs).map(
        (key) => (onlyValidate[key] = this.validations[key])
      );
    } else {
      onlyValidate = Object.assign({}, this.validations);
    }
    const errors = validate(attrs || this.state, onlyValidate);
    this.setState({ errors });
    return errors;
  };

  handleChangeName = (event, value) => {
    this.setState({ name: value });
  };

  handleChangeSurname1 = (event, value) => {
    this.setState({ surname1: value });
  };

  handleChangeSurname2 = (event, value) => {
    this.setState({ surname2: value });
  };

  handleChangeEmail = (event, value) => {
    this.setState({ email: value });
  };

  handleChangeTelephone = (event, value) => {
    this.setState({ telephone: value });
  };

  handleChangeContactHours = (event, index, value) => {
    this.setState({ contactHours: event.target.value });
  };

  handleChangeInvoice = (invoice) => {
    this.setState({ invoice });
  };

  handleCheckConsent = (event, value) => {
    this.setState({ consent: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { state } = this;
    const errors = this.validate(state);
    if (_.isEmpty(errors)) {
      console.log("Yay! No validation errors!");
      const data = {
        name: state.name,
        surname1: state.surname1,
        surname2: state.surname2 || '',
        email: state.email,
        telephone: state.telephone,
        contactHours: state.contactHours,
        consent: state.consent,
      };

      if (this.props.attachInvoice) {
        data.invoice = state.invoice;
      }

      if (this.props.onSubmit) {
        this.props.onSubmit(data);
      }

      if (Cookies.marketingConsentGiven()) {
        marketingHook();
      }
    }
  };

  handleGoToContract = async () => {
    dispatchNewRoute(contraction_url);
  };

  handleGoToDashboard = async () => {
    dispatchNewRoute('/');
  };


  render() {
    const { errors } = this.state;
    const consentTemplate = _.template(i18n.t('common:text.lead_contact_consent'));
    const consentText = consentTemplate({
      link: _.get(Settings, 'links.calculatorConditions', ''),
    });
    const consentMd = <RichContent content={consentText} />;

    const contactHours = this.contactHours
    ? this.contactHours.map((c) => {
        return (
          <MenuItem key={c.value} value={c.value}>{c.name}</MenuItem>
        );
      })
    : [];

    return (
      <div>
        <div className="lead-contact-form">
          { this.props.changePowerHelp && 
          <div className="left-column">
            <div>
              <p>
                <strong>
                  {i18n.t('common:text.lead_contact_chpower_already_client')}
                </strong>
              </p>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={this.handleGoToDashboard}
              >
                {i18n.t('common:text.lead_contact_dashboard')}
              </Button>
            </div>
            <div>
              <p>
                {i18n.t('common:text.lead_contact_chpower_no_client')}
              </p>
              <Button
                variant={'contained'}
                color="secondary"
                className="fancy-secondary-btn"
                onClick={this.handleGoToContract}
              >
                {i18n.t('common:text.lead_contact_contract')}
              </Button>
            </div>
          </div> }
          <div className="form">
            <div>
              <TextField
                label={i18n.t('common:text.lead_contact_name')}
                name="name"
                onChange={this.handleChangeName}
                value={this.state.name || ""}
                fullWidth={true}
                error={!!(errors.name)}
              />
              {!!(errors.name) &&
                <FormHelperText error={true}>
                  {errors.name}
                </FormHelperText>
              }
            </div>
            <div>
              <TextField
                label={i18n.t('common:text.lead_contact_surname1')}
                name="surname"
                onChange={this.handleChangeSurname1}
                value={this.state.surname1 || ""}
                fullWidth={true}
                error={!!(errors.surname1)}
              />
              {!!(errors.surname1) &&
                <FormHelperText error={true}>
                  {errors.surname1}
                </FormHelperText>
              }
              <TextField
                label={i18n.t('common:text.lead_contact_surname2')}
                name="surname"
                onChange={this.handleChangeSurname2}
                value={this.state.surname2 || ""}
                fullWidth={true}
                error={!!(errors.surname2)}
              />
              {(errors.surname2) &&
                <FormHelperText error={true}>
                  {errors.surname2}
                </FormHelperText>
              }
            </div>
            <div>
              <TextField
                label={i18n.t('common:text.lead_contact_email')}
                name="email"
                onChange={this.handleChangeEmail}
                value={this.state.email || ""}
                fullWidth={true}
                error={!!(errors.email)}
              />
              {!!(errors.email) &&
                <FormHelperText error={true}>
                  {errors.email}
                </FormHelperText>
              }
            </div>
            <div>
              <TextField
                label={i18n.t('common:text.lead_contact_telephone')}
                name="telephone"
                onChange={this.handleChangeTelephone}
                value={this.state.telephone || ""}
                fullWidth={true}
                error={!!(errors.telephone)}
              />
              {!!(errors.telephone) &&
                <FormHelperText error={true}>
                  {errors.telephone}
                </FormHelperText>
              }
            </div>
            <div>
              <FormControl fullWidth={true}>
                <InputLabel shrink htmlFor="">
                  {i18n.t('common:text.lead_contact_hours') + "*"}
                </InputLabel>
                <Select
                  onChange={this.handleChangeContactHours}
                  value={this.state.contactHours}
                  className="select-field"
                  error={!!(errors && errors.contactHours)}
                >
                  {contactHours}
                </Select>
                {!!(errors.contactHours) &&
                  <FormHelperText error={true}>
                    {errors.contactHours}
                  </FormHelperText>
                }
              </FormControl>
            </div>
            {this.props.attachInvoice &&
              <div className="attachment">
                <MultiUploadButton
                  min={1}
                  max={3}
                  value={this.state.invoice}
                  onChange={this.handleChangeInvoice}
                  label={i18n.t('common:text.lead_contact_invoice_attachment')}
                  anotherLabel={i18n.t('common:text.attachment_add')}
                  removeLabel={i18n.t('common:text.attachment_remove')}
                  errorText={errors && errors.invoice}
                />
              </div> }
            <div style={{ textAlign: "left" }}>
              <div style={{ marginTop: '3rem' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.consent || false}
                      onChange={this.handleCheckConsent}
                      style={{ float: 'left', width: '40px' }}
                    />
                  }
                  label={consentMd}
                />
              </div>
              {errors ? (
                <div style={{ color: "red", marginTop: "1rem" }}>
                  {errors.consent}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="submit-btn">
              <Button
                variant={'contained'}
                color="secondary"
                className="fancy-secondary-btn"
                onClick={this.handleSubmit}
              >
                {i18n.t('common:text.lead_contact_submit')}
              </Button>
            </div>
          </div>
        </div>
        <Grid container spacing={1} row alignItems="center" style={{padding: 20, paddingBottom: 0}}>
          <Grid item xs={4} sm={2} md={1}>
            <svg style={{height: 60, width: 60}}><TelephoneIcon /></svg>
          </Grid>
          <Grid item xs={8} sm={4} md={5}>
            <Typography variant="body1" style={{fontWeight: "bold", color: "gray"}}>{organization.phone_crm || ''}</Typography>
            <Typography variant="body2" style={{color: "gray"}}>{organization.schedule || ''}</Typography>
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <svg style={{height: 60, width: 60}}><EmailIcon /></svg>
          </Grid>
          <Grid item xs={8} sm={4} md={5}>
            <Typography variant="body1" style={{fontWeight: "bold", color: "gray"}}>{organization.email || ''}</Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

LeadContact.propTypes = {
  onSubmit: PropTypes.func,
  changePowerHelp: PropTypes.bool,
  attachInvoice: PropTypes.bool,
};

export default LeadContact;
