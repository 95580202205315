import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { data_fetch_api_resource, data_fetch_api_resource_with_params } from "../../../utils/http_functions";
import { dayjs } from "../../../config";
import { regional_settings } from "../../../constants"

import { CircularProgress, Grid } from "@material-ui/core"

import { BarChart, AreaChart } from "../../Chart/v2";
import DateSelector from "./../DateSelector";

const defaults = {
  type: 'monthly',
  startDate: dayjs().date(1),
  endDate: dayjs().month(dayjs().month()+1).date(0)
};

const CCHChart = ({contractId}) => {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [dates, setDates] = useState({...defaults});

  useEffect(() => {
    fetchChartSpec();
  }, [dates, contractId]);

  const handleDatesChange = (newDates) => {
    setDates(newDates);
  }

  const fetchChartSpec = () => {
    const translations = {
      'activa': t('common:text.contract_cch_active'),
      'exportada': t('common:text.contract_cch_exported'),
      'reactiva': t('common:text.contract_cch_reactive')
    }

    const spec_url = `contracts/${contractId}/cch/chart/${dates.type}/` +
      (dates.type !== 'monthly' ? `?start=${dates.startDate.toISOString()}&end=${dates.endDate.toISOString()}` : '');

    setLoading(true);
    data_fetch_api_resource_with_params(token, spec_url, {translations}, 2)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setChartData(response.data);
        } else {
          setChartData(null);
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <DateSelector onDatesChange={handleDatesChange} contractId={contractId} defaults={defaults} loading={loading} />

      <Grid container justifyContent="center" style={{ paddingTop: 20 }}>
        {loading &&
          <Grid item xs={12} align="center">
            <CircularProgress />
          </Grid>
        }
        {!loading &&
          <Grid item xs={12}>
            {chartData && <>
              {dates.type === 'hourly' &&
                <AreaChart
                  data={chartData}
                  unit={regional_settings.energy_unit}
                  showTooltipInfo={true}
                />
              }
              {dates.type !== 'hourly' &&
                <BarChart
                  data={chartData}
                  unit={regional_settings.energy_unit}
                />
              }
            </>}
            {!chartData &&
              <div style={{ textAlign: "center" }}>{t('common:text.contract_cch_chart_no_data')}</div>
            }
          </Grid>
        }
      </Grid>
    </>
  )
}

export default CCHChart;