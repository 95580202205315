import { adjacent, brightness, convert, fade, invertLightness } from 'chromatism';
import { colors_nova } from "../constants";

export function generateColorList(startColorHex, sections) {
  if (sections-1 <= colors_nova.length) {
    return colors_nova;
  } else {
    const startColor = convert( startColorHex );

    if (isColorTooBright(startColor.rgb) || isColorTooDark(startColor.rgb)) {
      const fromColor = ensureColorVisiblity(startColor); 
      const toColor = invertLightness(fromColor.hex)
      return fade(sections, fromColor.hex, toColor.hex).hex // Gradient style
    }
    else { 
      const startingColor = ensureColorVisiblity(startColor);
      return adjacent((360/sections)+20, sections, startingColor.hex).hex // Rainbow style
    }
  }
  
}

function getLuminance(r, g, b) {
  // Calculating relative luminance
  r = r / 255;
  g = g / 255;
  b = b / 255;

  return 0.299 * r + 0.587 * g + 0.114 * b;
}

function isColorTooDark(rgb, darkThreshold=0.20) {
  const luminance = getLuminance(rgb.r, rgb.g, rgb.b);
  // Returning true if the color is too dark, false otherwise
  return luminance < darkThreshold;
}
function isColorTooBright(rgb, brightThreshold=0.90) {
  const luminance = getLuminance(rgb.r, rgb.g, rgb.b);
  // Returning true if the color is too bright, false otherwise
  return luminance > brightThreshold;
}

function ensureColorVisiblity(chromatismColor) {
  if (isColorTooDark(chromatismColor.rgb)) {
    chromatismColor = brightness(20, chromatismColor.rgb)
  }
  if (isColorTooBright(chromatismColor.rgb)) (
    chromatismColor = brightness(-20, chromatismColor.rgb)
  )
  return chromatismColor;

}