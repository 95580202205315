import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../config';
import * as actionCreators from '../actions/auth';
import { validate_token, undefine_token } from "../utils/http_functions";
import { retrieve_token } from '../utils/auth';
import { getUrlParams } from "../utils/misc";
import { features } from "../settings";

import { i18n } from "../config";

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export function requireNoAuthentication(Component) {

    class notAuthenticatedComponent extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                loaded: false,
            };
        }

        static getDerivedStateFromProps(props, state) {
            return notAuthenticatedComponent.checkAuth(props, state);
        }

        static checkAuth(props, state) {
            let newState = {};

            if (props.isAuthenticated) {
                var urlParams = getUrlParams();
                if (features.oidc && urlParams && urlParams.id_client && urlParams.secret) {
                    // If the user is already authenticated and have id_client
                    // and secret in the URL, we must invalidate the session and login with the params.
                    
                    // Invalidate session
                    undefine_token();
                    // Redirect to login
                    history.push(i18n.t('common:url.connect'));
                } else {
                    newState = {
                        loaded: true,
                    };
                }
            } else {
                const token = retrieve_token();
                if (token) {
                    validate_token(token)
                        .then(res => {
                            if (res.status === 200) {
                                props.loginUserSuccess(token);
                                history.push(i18n.t('common:url.invoices'));
                            } else {
                                newState = {
                                    loaded: true,
                                };
                            }
                        })
                        .catch(error => {
                            // Token probably not valid or has expired so we remove it
                            undefine_token();
                            newState = {
                                loaded: true,
                            };
                        });
                } else {
                    newState = {
                        loaded: true,
                    };
                }
            }
            return newState;
        }

        render() {
            return (
                <div>
                    {!this.props.isAuthenticated && this.state.loaded
                        ? <Component {...this.props} />
                        : null
                    }
                </div>
            );

        }
    }

    notAuthenticatedComponent.propTypes = {
        loginUserSuccess: PropTypes.func,
        isAuthenticated: PropTypes.bool,
    };

    return connect(mapStateToProps, mapDispatchToProps)(notAuthenticatedComponent);
}
