import _ from "lodash";
import React from "react";
import LeadContact from "../LeadContact";
import FailedQueryIcon from "./icons/failed-query.png";
import * as Sentry from '@sentry/react';

import {
  data_create_api_resource,
  dispatchNewRoute
} from "../../utils/http_functions";
import { i18n, dayjs } from "../../config";
import Settings from "../../settings";

import { Button, IconButton, Grid } from "@material-ui/core"
import { ChevronLeft } from "@material-ui/icons"
import {contraction_url} from '../../constants/i18n';

const sortByName = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

class Result extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactSent: false,
      errors: {},
    };

    let daysInYear = 0;
    for (var i = 0; i < 12; i++) {
      daysInYear += dayjs().month(i).daysInMonth();
    }
    this.daysInYear = daysInYear;
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  handleSubmit = (data) => {
    data_create_api_resource(null, "price-compare/confirm", {
      cups: this.props.cups,
      ...data,
    });
    this.setState({ contactSent: true });
  };

  handleSeeOurProducts = () => {
    if (i18n.exists('common:url.ourProducts')){
      dispatchNewRoute(i18n.t('common:url.ourProducts'));  
    }
  };

  handleContractNow = () => {
    dispatchNewRoute(contraction_url);
  };

  getTaxes = (taxes) => {
    const keys = Object.keys(taxes);
    let taxList = [];
    if (keys.length) {
      taxList = keys.map((k) => {
        let name = taxes[k].name;
        let specialTax = name.indexOf("Impuesto especial") !== -1;
        if (specialTax) {
          name = i18n.t('common:text.price_comparator_detail_summary_iede_tax');
        }
        return (
          <div className={specialTax ? "special-tax" : "tax"} key={k}>
            {name}
            <span className="amount">
              {Number(taxes[k].amount).toFixed(2)} €
            </span>
          </div>
          );
        }
      );
    }
    return taxList;
  };

  renderDetails = (result) => {
    let total = result.total_amount,
      totalPower = 0,
      totalEnergy = 0,
      totalOther = 0;

    if (result && result.operations) {
      const operations = result.operations;
      if (operations.lines) {
        if (operations.lines.potencia && operations.lines.potencia.length) {
          totalPower = operations.lines.potencia.reduce(
            (t, { price_unit, quantity, multi }) =>
              t + price_unit * quantity * multi,
            0
          );
        } else {
          Sentry.captureMessage(`operations.lines = ${JSON.stringify(operations.lines)}`);
        }
        if (operations.lines.energia && operations.lines.energia.length) {
          totalEnergy = operations.lines.energia.reduce(
            (t, { price_unit, quantity, multi }) =>
              t + price_unit * quantity * multi,
            0
          );
        }
        if (operations.lines.lloguer && operations.lines.lloguer.length) {
          totalOther = operations.lines.lloguer.reduce(
            (t, { price_unit, quantity, multi }) =>
              t + price_unit * quantity * multi,
            0
          );
        }
      }
    }

    const savingDetailTitle = _.template(i18n.t('common:text.price_comparator_saving_detail_title'));
    const taxes = this.getTaxes(result.operations.taxes);

    return (
      <div className="saving-detail">
        <div className="saving-detail-note">
          {i18n.t('common:text.price_comparator_saving_detail_note')}
        </div>
        <h2 className="saving-detail-title">
          {savingDetailTitle({
            companyName: _.get(Settings, 'organization.name')
          })}
        </h2>
        <h3 className="power-sum">
          {i18n.t('common:text.price_comparator_detail_summary_power')}
          <Grid container justifyContent="flex-end">
            {Number(totalPower).toFixed(2)} €
          </Grid>
        </h3>
        {result.operations.lines.potencia
          .sort(sortByName)
          .map((line, index) => {
            return (
              <div key={index}>
                {line.name} — {line.price_unit} €/kW {i18n.t('common:text.day')} × {line.quantity} kW {i18n.t('common:text.contracted_plural')} ×{" "}
                {line.multi} {i18n.t('common:text.days')}
                <Grid container justifyContent="flex-end">
                  {Number(
                    line.quantity * line.price_unit * line.multi
                  ).toFixed(2)}{" "}
                  €
                </Grid>
              </div>
            );
          })}

        <h3 className="energy-sum">
          {i18n.t('common:text.price_comparator_detail_summary_energy')}
          <Grid container justifyContent="flex-end">
            {Number(totalEnergy).toFixed(2)} €
          </Grid>
        </h3>
        {result.operations.lines.energia
          .sort(sortByName)
          .map((line, index) => {
            return (
              <div key={index}>
                {line.name} — {line.quantity} kWh × {line.price_unit} €/kWh{" "}
                <Grid container justifyContent="flex-end">
                  {Number(line.quantity * line.price_unit).toFixed(2)} €
                </Grid>
              </div>
            );
          })}

        <h3 className="other-sum">
          {i18n.t('common:text.price_comparator_detail_summary_other')}
          <Grid container justifyContent="flex-end">
            {Number(totalOther).toFixed(2)} €
          </Grid>
        </h3>
        {result.operations.lines.lloguer.map((line, index) => {
          return (
            <div key={index}>
              {i18n.t('common:text.price_comparator_detail_summary_meter_rent')} {line.price_unit} €/{i18n.t('common:text.day')} × {line.quantity}{" "}
              {i18n.t('common:text.days')}{" "}
              <Grid container justifyContent="flex-end">
                {Number(line.quantity * line.price_unit).toFixed(2)} €
              </Grid>
            </div>
          );
        })}

        {taxes}

        <div className="total">
          Total {Number(total).toFixed(2)} €
        </div>
      </div>
    );
  }

  renderSavingResult = (result) => {
    return (
      <div className="result">
        {this.renderHeader(result.header)}
        <div>
          <div>
            {result.operations && this.renderDetails(result)}
          </div>
          <div>
            {this.renderContent(result.content)}
          </div>
        </div>
        <div>
          <div>
            <div className="contact-container">
              <h2>{i18n.t('common:text.price_comparator_i_am_interested')}</h2>
              <LeadContact onSubmit={this.handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHeader = (header) => {
    return <div dangerouslySetInnerHTML={{ __html: header }} />;
  };

  renderContent = (content) => {
    let renderResult = null;
    if (content) {
      const buttons = (
        <div className="contract-now">
          <div className="see-our-products-btn">
            <Button
              className="secondary-btn"
              fullWidth={true}
              buttonStyle={{ height: "52px" }}
              labelStyle={{ fontSize: "31px", fontWeight: "600" }}
              onClick={this.handleSeeOurProducts}
            >
              {i18n.t('common:text.price_comparator_contract_now')}
            </Button>
          </div>
          <div className="contract-now-btn">
            <Button
              className="secondary-btn"
              fullWidth={true}
              buttonStyle={{ height: "52px" }}
              labelStyle={{ fontSize: "31px", fontWeight: "600" }}
              onClick={this.handleContractNow}
            >
              {i18n.t('common:text.price_comparator_contract_now_btn')}
            </Button>
          </div>
        </div>
      );
      renderResult = (
        <div>
          <div 
            className="summary"
            onClick={this.handleSeeOurProducts}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {buttons}
        </div>
      );
    }

    return renderResult;
  };

  renderContact = () => {
    return (
      <div className="contact-container">
        <img src={FailedQueryIcon} alt=""/>
        <h1>{i18n.t('common:text.price_comparator_contact_title')}</h1>
        <h2>{i18n.t('common:text.price_comparator_contact_subtitle')}</h2>
        <LeadContact onSubmit={this.handleSubmit} attachInvoice={true} />
      </div>
    );
  };

  render() {
    const { props } = this;
    const { contactSent } = this.state;

    return (
      <div>
        <IconButton onClick={this.props.onGoBack} className="btn-back">
          <ChevronLeft className="btn-back-icon" />
        </IconButton>
        {contactSent ? (
          <h3>{i18n.t('common:text.price_comparator_contact_sent')}</h3>
        ) : (
          props.saving &&
            this.renderSavingResult(props.saving)
        )}
      </div>
    );
  }
}

export default Result;
