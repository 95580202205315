import React, { Component } from 'react';
import { getAvailableResources } from "../../i18n";
import { locale_code } from '../../constants/i18n';
import { i18n, dayjs } from "../../config";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Cookies from '../../utils/cookies';

import { Button, Menu, MenuItem } from "@material-ui/core"
import { ExpandMore } from '@material-ui/icons';

class UILangSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            currentLang: locale_code.split('-')[0],
            element: null,
        };
        this.langs = getAvailableResources();
    }

    handleMenuOpen = (event) => {
        this.setState({ openMenu: true });
        this.setState({ element: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ openMenu: false });
        this.setState({ element: null });
    };

    handleChangeLang = (value) => {
        i18n.changeLanguage(value);
        Cookies.saveLang(value);
        dayjs.locale(value);
        this.setState({ currentLang: locale_code.split('-')[0] });
        this.setState({ openMenu: false });
        this.setState({ element: null });
    };

    render() {
        const langs = this.langs.map((i) => {
          return (
            <MenuItem
              key={i}
              value={i}
              onClick={() => this.handleChangeLang(i)}
            >{i.toUpperCase()}</MenuItem>
          );
        });

        return (
            <>
                <Button
                    className={"ui-lang-selector"}
                    onClick={this.handleMenuOpen}
                >
                    {i18n.t('common:locale.name') || ''}
                    {<ExpandMore />}
                </Button>
                <Menu
                    open={this.state.openMenu}
                    anchorEl={this.state.element}
                    onClose={this.handleClose}
                >
                    {langs}
                </Menu>
            </>
        );
    }
}

export default UILangSelector;