import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Slider, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBateriaVirtualPercentages, setVirtualBatteryPercentage } from "../../actions/bateriaVirtual";
import { i18n } from "../../config";
import BateriaVirtualDistributedPercentageDetails from "./BateriaVirtualDistributedPercentageDetails";
import BateriaVirtualSlider from "./BateriaVirtualSlider";


/**
 * Feature component
 * 
 * Responsible for rendering a dialog with a slider to edit percentage values related to the distribution of virtual battery percentages
 * 
 * - Delegate the rendering of percentage distribution to the `BateriaVirtualDistributedPercentageDetails` component.
 * 
 */
const BateriaVirtualDialog = ({open, bateria_virtual, onClose}) => {
  const dispatch = useDispatch();
  const [ percentatge, setPercentatge ] = useState(); 
  const view_contract = useSelector((state) => state.contracts.view_contract);
  const {
    waitingChangeRequest,
    isFetchingBateriaVirtualPercentages,
    bateriaVirtualPercentages,
    error
  } = useSelector((state) => state.bateriaVirtual);
  const serverBateriaVirtual = bateriaVirtualPercentages?.find((e) => e.bateria_id === bateria_virtual.id);

  useEffect(() => { 
    setPercentatge(bateria_virtual.pes_percentatge);
    dispatch(fetchBateriaVirtualPercentages(bateria_virtual.bateria.id));
  }, [bateria_virtual, open]);

  const handleSubmit = () => {
    dispatch(setVirtualBatteryPercentage(view_contract.name, bateria_virtual.bateria.id, percentatge))
      .then((a) => {
        dispatch(fetchBateriaVirtualPercentages(bateria_virtual.bateria.id));
      })
      .catch(err => {
        setPercentatge(serverBateriaVirtual.percentage);
      })
  };


  return (
    <Dialog 
      open={open} 
      onClose={onClose}
    >
      <DialogTitle>
        {i18n.t('common:text.virtual_battery_modify_percentage')}
      </DialogTitle>
      <DialogContent style={{overflow: "hidden"}}>
        {error && <Alert severity="error">{ i18n.t('common:text.message_cant_do_operation') }</Alert>}
        {i18n.t('common:text.virtual_battery_notice')}
        <BateriaVirtualSlider
          style={{marginTop: "2rem"}}
          value={percentatge}
          aria-labelledby="discrete-slider"
          step={1}
          min={0}
          max={100}
          onChange={(event, newValue) => setPercentatge(newValue)}
          disabled={waitingChangeRequest}
        />
        { (!isFetchingBateriaVirtualPercentages && !bateriaVirtualPercentages) ? null : <BateriaVirtualDistributedPercentageDetails/>}
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='primary' onClick={onClose} disabled={false}>
          {i18n.t('common:text.online_send_close')}
        </Button>
        <Button
          key={'send'}
          variant={'text'}
          color={'primary'}
          onClick={handleSubmit}
          disabled={waitingChangeRequest || serverBateriaVirtual?.percentage == percentatge} 
        >
          { waitingChangeRequest ? <CircularProgress color="secondary" style={{ marginRight: 10 }} size={25} /> : null }
          { i18n.t('common:text.contract_modification') }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BateriaVirtualDialog;