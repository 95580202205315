import React from "react";
import LegacyVATField, { validateVAT, isCompanyVAT } from "../VATField";

const VATField = ({ input, meta, readOnly, ...rest }) => { 
  return (
  <LegacyVATField
    {...input}
    {...rest}
    disabled={readOnly}
    error={meta.error}
    touched={meta.touched}
  />
);
};

export default VATField;

export { validateVAT, isCompanyVAT };