import React, { useState } from "react";
import { dispatchNewRoute } from "../../utils/http_functions";
import { logoutAndRedirect } from '../../actions/auth';
import { clearContractsInvoices } from '../../actions/contracts';

import jwt_decode from "jwt-decode";
import { LogoBar } from "../LogoBar";
import { history } from '../../config';

import UILangSelector from "../UILangSelector";
import Settings from "../../settings"; 

import { Button, Divider, MenuItem, AppBar, Toolbar, Menu, Box, Grid } from "@material-ui/core";
import DinamicServerNavigation from "../ServerElements/DinamicServerNavigation";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const UserProfileButton = ({color, user, logout}) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState(null);

  const handleMenuOpen = (event) => {
    setOpen(true);
    setElement(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setElement(null);
  };

  const dispatchRoute = (route) => {
    dispatchNewRoute(route);
    setOpen(false);
  }

  return <>
    <Button
      color={color}
      variant={'contained'}
      onClick={handleMenuOpen}
    >
      {t('common:text.myprofile_title')}
    </Button>
    <Menu
      open={open}
      anchorEl={element}
      onClose={handleClose}
    >
      <Box
        className="header-horizontal-profile-name" m={1}
      >
        <MenuItem key={"0"}>
          <div>
            {t('common:text.user_welcome')} <br />
            <span style={{whiteSpace: "nowrap", fontWeight: "bold"}}>{user.name}</span>
            <br />
            {user.email}
          </div>
        </MenuItem>
      </Box>
      <Divider />
      <Box m={1}>
        {!(Settings.features?.oidc ?? false) ?
          <MenuItem
            key={"1"}
            value="1"
            onClick={() => dispatchRoute(t('common:url.changePassword'))}
          >
            {t('common:text.change_password_view_title')}
          </MenuItem>
          :
          <MenuItem
            key={"1"}
            value="1"
            onClick={() => {
              window.open(Settings.oidc.changePasswordUrl, "_blank");
            }}
          >{t('common:text.change_password_title')}</MenuItem>
        }
        {(Settings.features?.CRMcases ?? false) &&
          <MenuItem
            key={"1"}
            value="1"
            onClick={() => {
              history.push(t('common:url.CRM_list'));
            }}
          >{t('common:text.crm_manage_requests')}</MenuItem>
        }
        <MenuItem
          key={"2"}
          value="2"
          onClick={logout}
        >{t('common:text.logout_title')}</MenuItem>
      </Box>
    </Menu>
  </>
}

const Header = ({color}) => {
  const dispatch = useDispatch();
  const { isAuthenticated, token } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const logout = (e) => {
    e.preventDefault();
    dispatch(logoutAndRedirect());
  };

  const goToInvoices = () => {
    dispatch(clearContractsInvoices());
    dispatchNewRoute(t('common:url.invoices'));
  }

  let current_path = history.location.pathname;
  let user = null;

  // <div className="header-horizontal">
  let noUser = (
    <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
      {(current_path !== t('common:url.login')) &&
        !(Settings.features?.oidc ?? false) &&
          <Grid item>
            <Button
              color={color}
              variant={'contained'}
              onClick={() => dispatchNewRoute(t('common:url.login'))}
            >
              {t('common:text.login_title')}
            </Button>
        </Grid>
      }
      {(Settings.features?.signup ?? false) &&
        !(Settings.features?.oidc ?? false) &&
          <Grid item>
            <Button
              color={color}
              variant={'contained'}
              onClick={() => dispatchNewRoute(t('common:url.activate'))}
            >
              {t('common:text.login_view_button_activate')}
            </Button>
          </Grid>
      }
      {(Settings.features?.unsignedContact ?? false) &&
        (Settings.features?.contact ?? false) &&
          <Grid item>
            <Button
              color={color}
              variant={'contained'}
              onClick={() => dispatchNewRoute(t('common:url.unsigned_contact'))}
            >
              {t('common:text.contact_title')}
            </Button>
          </Grid>
      }
      {(current_path !== t('common:url.gasPriceComparator')) &&
        (Settings?.comparator?.enabled ?? false) &&
          <Grid item>
            <Button
              color={color}
              variant={'contained'}
              onClick={() => dispatchNewRoute(t('common:url.priceComparator'))}
            >
              {t('common:text.menu_price_comparator_title')}
            </Button>
          </Grid>
      }
      {(current_path !== t('common:url.gasPriceComparator')) &&
        (Settings?.powerCalculator?.enabled ?? false) &&
          <Grid item>
            <Button
              color={color}
              variant={"contained"}
              onClick={() => dispatchNewRoute(t('common:url.powerCalculator'))}
            >
              {t('common:text.menu_power_calculator_title')}
            </Button>
          </Grid>
      }
      <UILangSelector />
    </Grid>
  );

  if (token) {
    const token_values = jwt_decode(token);
    user = (
      <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Button
            color={color}
            variant={'contained'}
            onClick={() => dispatchNewRoute(t('common:url.contracts'))}
          >
            {t('common:text.contracts_title')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color={color}
            variant={'contained'}
            onClick={() => goToInvoices()}
          >
            {t('common:text.invoices_title')}
          </Button>
        </Grid>
        {(Settings.features?.contact ?? false) &&
          <Grid item>
            <Button
              color={color}
              variant={'contained'}
              onClick={() => dispatchNewRoute(t('common:url.contact'))}
            >
              {t('common:text.contact_title')}
            </Button>
          </Grid>
        }
        {Settings?.features?.reclamATCMenu &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() => this.dispatchRoute(i18n.t('common:url.reclamations'))}
              >
                {i18n.t('common:text.reclamations_title')}
              </Button>
            </Grid>
        }
        {Settings?.features?.contactAutoReads &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() =>
                  this.dispatchRoute(i18n.t('common:url.contact') + '?autolectures=true')
                }
              >
                {i18n.t('common:text.send_readings_label')}
              </Button>
            </Grid>
        }
        { Settings?.features?.enableServerElements && (
          <DinamicServerNavigation wrapperComponent={Grid} wrapperProps={{item:true}} clickableProps={{color: color}}/>
        )}
        <Grid item>
          <UserProfileButton color={color} logout={logout} user={token_values} />
        </Grid>
        <Grid item>
          <UILangSelector />
        </Grid>
      </Grid>
    );
  }

  return (
    <header role="banner">
      <div style={{flexGrow: 1}}>
        <AppBar position={'static'}>
          <Toolbar>
            <LogoBar style={{flexGrow: 1}} openLinkInNewTab={false} />
            {isAuthenticated ? user : noUser}
          </Toolbar>
        </AppBar>
      </div>
    </header>
  );
}

export default Header;
