import React, { useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, Legend, Line, Brush, Bar, ResponsiveContainer } from 'recharts';
import { default as CustomTooltip } from "./../Tooltip";
import { theme } from '../../../config';
import { useChart } from './useChart';

const styles = {
  legend: {
    width: "100%",
    paddingTop: "1vh",
  },
};

export const BarChart = ({ 
  data, 
  components,
  unit = 'kWh', 
  groupTitle = "", 
  ignoreMaxContracts = false,
 }) => {
  const [hover, setHover] = useState(null);

  const handleLegendMouseEnter = (e) => {
    setHover(e.dataKey);
  };

  const handleLegendMouseLeave = (e) => {
    setHover(null);
  };

  const {colors, dataAdapted, fields, toggleBarVisibility} = useChart({
    data: data ?? [], 
    components, 
    compoundBars: true, 
    ignoreMaxContracts
  });

  const getStackedId = (component) => {
    if (component.includes("reactiva")) {
      return "reactiva";
    } else if (component.includes("exportada")) {
      return "exportada";
    } else {
      return "activa"
    }
  }

  const createBars = () => {
    return fields && Object.entries(fields).map((component, idx) => {
      if (component[0] !== 'total') {
        return <Bar
          legendType="square"
          unit={unit}
          isAnimationActive={true}
          key={"bar" + idx}
          type='monotone'
          dataKey={component[0]}
          stackId={getStackedId(component[0])}
          stroke={"#808080"}
          strokeWidth={hover === component[0] ? 1 : 0}
          fill={colors[idx]}
          hide={component[1] === false}
          fillOpacity={(hover === component[0] || !hover) ? 1 : 0.5}
        />
      }
    });
  }

  const createTotalLine = () => {
    return fields && <Line
      unit={unit}
      isAnimationActive={true}
      key={"line"}
      type='monotone'
      dataKey={'total'}
      stroke={"black"}
      fill={"white"}
      hide={fields.total === false}
      strokeOpacity={(hover === 'total' || !hover) ? 1 : 0.5}
      dot={{ opacity: ((hover === 'total' || !hover) ? 1 : 0.5), strokeOpacity: ((hover === 'total' || !hover) ? 1 : 0.5) }}
    />;
  }

  return (
    <ResponsiveContainer height={500}>
      <ComposedChart
        data={dataAdapted}
        margin={{ top: 20, left: 20 }}
        stackOffset="sign"
      >
        <XAxis dataKey="name" angle={-45} height={55} dy={15} label={{ value: groupTitle, fill: '#666666', paddingTop: 20 }} />
        <YAxis label={{ value: unit, angle: unit.length > 1 ? -90 : 0, position: 'insideLeft', fill: '#666666' }} />
        <CartesianGrid strokeDasharray="3 3"/>
        {createBars()}
        {createTotalLine()}
        
        <Legend
          width={100}
          layout="horizontal"
          align="center"
          formatter={(value, entry, index) => {
            return <span style={{ 
              color: entry.inactive ? "gray" : "black", 
              textShadow: entry.value === hover ? `0 2px 10px ${colors[index]}` : "", 
              fontWeight: "400", 
              cursor: "pointer" }}
            >
              {value}
            </span>
          }}
          wrapperStyle={styles.legend}
          onClick={e => toggleBarVisibility(e.dataKey)}
          onMouseOver={handleLegendMouseEnter}
          onMouseOut={handleLegendMouseLeave}
        />

        <Tooltip content={<CustomTooltip groupTitle={groupTitle} />} />
        <Brush dataKey="name" height={30} stroke={theme.palette.primary.main} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}