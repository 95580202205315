import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";

import Routes from './routes';
import './style/index.scss';

import Cookies from './utils/cookies';
import CookieDialog from './components/CookieDialog';

import { ThemeProvider } from '@material-ui/core/styles';

import { i18n, theme, store, history } from './config';

import { initSentry } from './sentry'; 

initSentry();

history.listen( location => {
    Cookies.updateMarketingScripts(location);
    Cookies.updateStatisticScripts(location);
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Router history={history}>
                    <Routes/>
                </Router>
                <CookieDialog/>
            </I18nextProvider>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);
