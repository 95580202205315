import common_ca from './ca/common.json';
import common_es from './es/common.json';
import common_en from './en/common.json';
import custom_ca from '../overrides/i18n/ca.json';
import custom_es from '../overrides/i18n/es.json';
import custom_en from '../overrides/i18n/en.json';
import { getUrlParams } from "../utils/misc";
import jwt_decode from 'jwt-decode';
import { store } from "../store/configureStore";
import Cookies from '../utils/cookies';
import Settings from "../settings";
import _ from 'lodash';

const PARTNER_LANGUAGES = [
  { localeCode: "ca_ES", displayName: "Català" },
  { localeCode: "es_ES", displayName: "Español" },
  { localeCode: "en_US", displayName: "English" },
  { localeCode: "gl_ES", displayName: "Galego" },
  { localeCode: "fr_FR", displayName: "Français" },
  { localeCode: "de_DE", displayName: "Deutsch" },
];

const updateTranslationsWithCustom = () => {
  _.merge(common_ca, custom_ca);
  _.merge(common_es, custom_es);
  _.merge(common_en, custom_en);
}

updateTranslationsWithCustom();

export const resources = {
  ca: {
    common: common_ca,
  },
  es: {
    common: common_es,
  },
  en: {
    common: common_en,
  },
};

const getLangCode = (locale) => {
  return locale.indexOf('-') > 0 ? locale.split('-')[0]
    : locale.indexOf('_') > 0 ? locale.split('_')[0]
    : locale
}

const DEFAULT_LANGUAGE = getLangCode(Settings.language.default);

export const getLangByToken = (token) => {
  if (!token)
    return null;
  try {
    const token_values = jwt_decode(token);
    return getLangCode(token_values.locale);
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
}

const getLangByUrlParam = () => {
  try {
    const urlParams = getUrlParams();
    return (urlParams && urlParams.lang && getLangCode(urlParams.lang)) || null;
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
};

const getLangByCookies = () => {
  let lang = Cookies.getLang();
  return (typeof lang === 'string') ? getLangCode(lang) : null;
};

export const getLangByBrowser = () => {
  try {
    return getLangCode(navigator.language);
  } catch (e) {
    console.debug('ERROR getting browser language');
    return null;
  }
}

export const getLang = () => {
  console.debug('Getting lang by cookie');
  let lang = getLangByCookies();
  if (!lang) {
    const token = store.getState()?.auth?.token;
    
    if (token) {
      console.debug('Getting lang by token');
      lang = getLangByToken(token);
    }
    if (DEFAULT_LANGUAGE) {
      lang = DEFAULT_LANGUAGE;
    } else {
      console.debug('Getting lang by browser');
      lang = getLangByBrowser();
    }
  }
  return lang ?? DEFAULT_LANGUAGE;
}

export const getAvailablePartnerLangs = () => {
  const enabledLangs = _.get(Settings, "language.partner", []);
  return PARTNER_LANGUAGES.filter(
    (partnerLang) => enabledLangs.indexOf(partnerLang.localeCode) !== -1
  );
};

export const getAvailableResources = () => {
  let langs = getAvailablePartnerLangs();
  langs = langs.map(lang => lang.localeCode.split('_')[0].toLowerCase());
  return langs;
};