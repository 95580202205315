import React from 'react';
import PropTypes from 'prop-types';
import {checkVAT, spain} from "jsvat";
import validate from "validate.js";
import { i18n } from "../../config";
import FlexRow from "../FlexRow";

import { TextField, FormHelperText } from "@material-ui/core"

validate.validators.checkVAT = (value, options, key, attributes) => {
  if (!options.check) {
    return null
  }
  let revised_value = value === undefined ? "" : ((value.search("ES") === -1) ? "ES" + value : value)
  const result = checkVAT(revised_value, [spain]);
  console.log(result);
  console.log(value);
  if (!result.isValid) {
    return 'Documento no válido';
  } else {
    return null;
  }
};

export const validateVAT = (value) => {
  let revised_value = value === undefined ? "" : ((value.search("ES") === -1) ? "ES" + value : value)
  const result = checkVAT(revised_value, [spain]);
  return result.isValid;
};

export const isCompanyVAT = (value) => {
  let company = false;
  if (value) {
    company = "ABCDEFGHJNPQRSUVW".indexOf(value[0]) >= 0;
  }
  return company;
}

class VATField extends React.Component {
  constructor(props) {
    super(props);
    const value = this.props.value || '';
    this.state = {
      displayVAT: value.replace('ES', ''),
      error: this.props.error,
      touched: this.props.touched,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};
    if (state.error !== props.error || state.touched !== props.touched) {
      newState = {
        error: props.error || "",
        touched: props.touched || false
      };
    }
    if (state.displayVAT !== props.displayVAT) {
      newState = Object.assign(newState, {
        displayVAT: props.value ? props.value.replace('ES', '') : ''
      });
    }

    return newState;
  }

  handleChange = (event) => {
    let vat = event.target.value.toUpperCase();
    this.setState({ displayVAT: vat });

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  render() {
    const { props } = this;

    return (
      <FlexRow style={{ flexWrap: "wrap", flexDirection: "column" }}>
        <TextField
          label={props.label || i18n.t('common:text.vat_field_default_label')}
          name={props.name}
          onChange={this.handleChange}
          value={this.state.displayVAT}
          error={!!(this.state.error) && this.state.touched} 
          disabled={props.disabled}
          style={props.style}
        />
        {!!(this.state.error) && this.state.touched &&
          <FormHelperText error={true}>
            {this.state.error}
          </FormHelperText>
        }
      </FlexRow>
    );
  }

}

VATField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

VATField.defaultProps = {
  label: '',
  name: 'vat',
  value: '',
  error: "",
  touched: false,
  disabled: false,
};

export default VATField;
