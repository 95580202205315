import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import Settings from "../../settings";

import VATField, { validateVAT, isCompanyVAT } from "../form-fields/VATField";
import TextField from "../form-fields/TextField";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import CheckboxField from "../form-fields/CheckboxField";
import AddressField from "../form-fields/AddressField";
import PhoneField, { validPhone } from "../form-fields/PhoneField";
import EmailField, { validEmail } from "../form-fields/EmailField";
import LangSelectField from "../form-fields/LangSelectField";
import { i18n } from "../../config";

import { CircularProgress, Button, Grid, Box, Typography, Divider, Paper } from "@material-ui/core"
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RadioGroupField from "../form-fields/RadioGroupField";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutYou = (props) => {

  const handleGoBack = (values) => {
    if (props.onGoBack) {
      props.onGoBack(values);
    }
  };

  const handleSubmit = async (values) => {
    if (props.submitAboutYouData) { 
      values.company = isCompanyVAT(values.vat);
      props.submitAboutYouData(values);
    }
    if (props.onSubmit) {
      await props.onSubmit(values);
    }
  };

  const isReadOnly = (field) => {
    let r = false;
    if (props.contractationConfig && props.contractationConfig[field]) {
      r = true;
    }
    return r;
  };

  const theme = useTheme();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesBreakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box m={2} display={"flex"} style={{marginLeft: 0}}>
          <AccountCircleIcon fontSize="large" color="secondary" style={{padding: 10}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>
            {i18n.t('common:text.contractation_about')}{" "}{props.company ? i18n.t('common:text.contractation_enterprise') : i18n.t('common:text.contractation_you')}
          </Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          vat: props.vat,
          company: props.company,
          name: props.name,
          surName1: props.surName1,
          surName2: props.surName2,
          nifEmpresa: props.nifEmpresa,
          changeOwner: props.changeOwner,
          vatRepresentante: props.vatRepresentante,
          nameRepresentante: props.nameRepresentante,
          surName1Representante: props.surName1Representante,
          surName2Representante: props.surName2Representante,
          sameInvoiceAddress: props.sameInvoiceAddress,
          invoiceAddress: props.invoiceAddress,
          commsLanguage: props.commsLanguage,
          email: props.email,
          mobile: props.mobile,
          nifFaceup: props.nifFaceup,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.vat) {
            errors.vat = i18n.t('common:text.required_field');
          } else if (!validateVAT(values.vat ? "ES" + values.vat : "")) {
            errors.vat = i18n.t('common:text.vat_field_validation_invalid');
          }

          if (!values.name) {
            errors.name = i18n.t('common:text.required_field');
          }

          if (isCompanyVAT(values.vat)) {
            if (_.get(Settings, 'newContract.restrictCompanyVAT', false)) {
              errors.vat = i18n.t('common:text.vat_company_not_allowed');
            }
            if (_.isEmpty(values.vatRepresentante)) {
              _.set(errors, 'vatRepresentante', i18n.t('common:text.required_field'));
            } else if (
              !validateVAT(
                values.vatRepresentante ? "ES" + values.vatRepresentante : ""
              )
            ) {
              errors.vatRepresentante = i18n.t('common:text.vat_field_validation_invalid');
            }

            if (!values.nameRepresentante) {
              errors.nameRepresentante = i18n.t('common:text.required_field');
            }

            if (!values.surName1Representante) {
              errors.surName1Representante = i18n.t('common:text.required_field');
            }

            const nifEmpresaFaceUpErrors = validateFileUpload(values.nifEmpresa, {
              min: 1,
              minMessage: i18n.t('common:text.multiupload_validation_min'),
            });
            if (nifEmpresaFaceUpErrors) {
              _.set(errors, "nifEmpresa", nifEmpresaFaceUpErrors);
            }

          } else {
            if (!values.surName1) {
              errors.surName1 = i18n.t('common:text.required_field');
            }
          }

          if (!values.sameInvoiceAddress) {
            if (!values.invoiceAddress.zipcode) {
              _.set(errors, "invoiceAddress.zipcode", i18n.t('common:text.required_field'));
            } else if (values.invoiceAddress.zipcode.length !== 5) {
              _.set(
                errors,
                "invoiceAddress.zipcode",
                i18n.t('common:text.contractation_postal_code_length')
              );
            }

            // City
            if (!values.invoiceAddress.city) {
              _.set(errors, "invoiceAddress.city", i18n.t('common:text.required_field'));
            }

            // Street Type
            if (!values.invoiceAddress.tv) {
              _.set(errors, "invoiceAddress.tv", i18n.t('common:text.required_field'));
            }

            // Street Name
            if (!values.invoiceAddress.nv) {
              _.set(errors, "invoiceAddress.nv", i18n.t('common:text.required_field'));
            } else if (values.invoiceAddress.nv.length >= 256) {
              _.set(
                errors,
                "invoiceAddress.nv",
                i18n.t('common:text.address_validation_nv_length')
              );
            }

            // Street Number
            if (!values.invoiceAddress.pnp) {
              _.set(errors, "invoiceAddress.pnp", i18n.t('common:text.required_field'));
            } else if (values.invoiceAddress.pnp.length >= 9) {
              _.set(
                errors,
                "invoiceAddress.pnp",
                i18n.t('common:text.address_validation_pnp_length')
              );
            }

            // Block
            if (
              values.invoiceAddress.bq &&
              values.invoiceAddress.bq.length >= 4
            ) {
              _.set(
                errors,
                "invoiceAddress.bq",
                i18n.t('common:text.address_validation_bq_length')
              );
            }

            // Stair
            if (
              values.invoiceAddress.es &&
              values.invoiceAddress.es.length >= 4
            ) {
              _.set(
                errors,
                "invoiceAddress.es",
                i18n.t('common:text.address_validation_es_length')
              );
            }

            // Floor
            if (
              values.invoiceAddress.pt &&
              values.invoiceAddress.pt.length >= 10
            ) {
              _.set(
                errors,
                "invoiceAddress.pt",
                i18n.t('common:text.address_validation_pt_length')
              );
            }

            // Door
            if (
              values.invoiceAddress.pu &&
              values.invoiceAddress.pu.length >= 4
            ) {
              _.set(
                errors,
                "invoiceAddress.pu",
                i18n.t('common:text.address_validation_pu_length')
              );
            }
          }

          // changeOwner
          if (
            props.activeContract === "yes" && values.changeOwner == null && _.get(Settings, 'newContract.requireMarkChangeOwner', true)
          ) {
            _.set(
              errors,
              "changeOwner",
              i18n.t('common:text.required_field')
            );
          }

          // commsLanguage
          if (
            values.commsLanguage == null &&
            _.get(
              Settings,
              props.gas ? "newDualContract.commsLanguage" : "newContract.commsLanguage",
              true
            )
          ) {
            _.set(
              errors,
              "commsLanguage ",
              i18n.t('common:text.required_field')
            );
          }

          if (!values.mobile) {
            errors.mobile = i18n.t('common:text.required_field');
          } else if (!validPhone(values.mobile)) {
            errors.mobile = i18n.t('common:text.contractation_mobile_not_valid');
          }

          if (_.get(Settings, 'newContract.emailRequired', true) && !values.email) {
            errors.email = i18n.t('common:text.required_field');
          } else if (!validEmail(values.email)) {
            errors.email = i18n.t('common:text.contractation_email_not_valid');
          }

          const nifFaceupErrors = validateFileUpload(values.nifFaceup, {
            min: 1,
            minMessage: i18n.t('common:text.multiupload_validation_min'),
          });
          if (nifFaceupErrors) {
            _.set(errors, "nifFaceup", nifFaceupErrors);
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          // 'company' es fa servir per canviar el render del form. Fem l'and per restringir-ho quan no acceptem CIFs.
          let company = isCompanyVAT(values.vat) && !_.get(Settings, 'newContract.restrictCompanyVAT', false);
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{marginBottom: 20}}>
                  <Typography variant="h6" color="primary">
                    {i18n.t('common:text.contractation_about')}{" "}{props.company ? i18n.t('common:text.contractation_enterprise') : i18n.t('common:text.contractation_you')}
                  </Typography>
                  <Typography variant="body2">
                    {i18n.t('common:text.contractacion_about_home_info')}
                  </Typography>
                </Grid>
              </Grid>
              <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} style={matchesBreakpointSmDown ? {paddingRight: 24} : {}}>
                    <Typography variant="h6">
                      {i18n.t('common:text.contractation_personal_data')}
                    </Typography>
                    <Field
                      name="vat"
                      component={VATField}
                      label={
                        i18n.t(
                          _.get(Settings, 'newContract.restrictCompanyVAT', false) ?
                            'common:text.contractation_nif_without_cif'
                          :
                            'common:text.contractation_nif'
                        )
                      }
                      readOnly={isReadOnly("vat")}
                      style={{width: "100%"}}
                    />
                  </Grid>
                  <Grid container item xs={12} spacing={3} style={{paddingRight: 0}}>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="name"
                        component={TextField}
                        style={{width: "100%"}}
                        label={
                          company
                            ? i18n.t('common:text.contractation_social_reason')
                            : i18n.t('common:text.contractation_name')
                        }
                        readOnly={isReadOnly("name")}
                      />
                    </Grid>
                    {!company && (
                      <Grid item xs={12} md={4}>
                        <Field
                          name="surName1"
                          component={TextField}
                          label={i18n.t('common:text.contractation_surname1')}
                          readOnly={isReadOnly("surName1")}
                          style={{width: "100%"}}
                        />
                      </Grid>
                    )}
                    {!company && (
                      <Grid item xs={12} md={4}>
                        <Field
                          name="surName2"
                          component={TextField}
                          label={i18n.t('common:text.contractation_surname2')}
                          readOnly={isReadOnly("surName2")}
                          style={{width: "100%"}}
                        />
                      </Grid>
                    )}
                    <Grid container item xs={12}>
                      <Grid item xs={12} md={4}>
                        <Field
                          name="mobile"
                          component={PhoneField}
                          label={i18n.t('common:text.contractation_mobile_phone')}
                          readOnly={isReadOnly("mobile")}
                          style={{width: "100%"}}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12} md={4}>
                        <Field
                          name="email"
                          component={EmailField}
                          label={
                            i18n.t('common:text.contractation_email')
                            + `${_.get(Settings, 'newContract.emailRequired', true) ? "*" : ""}`
                          }
                          readOnly={isReadOnly("email")}
                          style={{width: "100%"}}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                  </Grid>
                  {company &&
                    <>
                      <Grid container spacing={3} item xs={12} style={{paddingRight: 0, paddingTop: 0}}>
                        <Grid item xs={12} style={matchesBreakpointMd ? {paddingBottom: 0} : {}}>
                          <Typography variant="h6">
                            {i18n.t('common:text.contractation_legal_representative')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} style={{paddingRight: 0}}>
                          <Field
                            name="vatRepresentante"
                            label={i18n.t('common:text.contractation_nif')}
                            component={VATField}
                            style={{width: "100%"}}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          name="nameRepresentante"
                          component={TextField}
                          label={i18n.t('common:text.contractation_legal_representative_name')}
                          style={{width: "100%"}}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          name="surName1Representante"
                          component={TextField}
                          label={i18n.t('common:text.contractation_legal_representative_surname1')}
                          style={{width: "100%"}}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          name="surName2Representante"
                          component={TextField}
                          label={i18n.t('common:text.contractation_legal_representative_surname2')}
                          style={{width: "100%"}}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Paper style={{padding: 15}} variant="outlined">
                          <Field
                            name="nifFaceup"
                            component={FileUploadField}
                            min={1}
                            max={2}
                            label={i18n.t('common:text.contractation_dni_front') + "*"}
                            hint={i18n.t('common:text.contractation_doc_invoice_helper', {doc: 'DNI'})}
                            anotherLabel={i18n.t('common:text.contractation_dni_back')}
                            removeLabel={i18n.t('common:text.remove')}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider/>
                      </Grid>
                    </>
                  }
                  {props.activeContract === "yes" && <Grid item xs={12}>
                    <Paper elevation={3} style={{padding: 15}}>
                      <Typography variant="h6">
                        { _.get(Settings, 'newContract.nameInChangeOwnerQuestion', false) ?
                          i18n.t("common:text.contractation_change_owner_question_named", {owner: props.name + " " + props.surName1 + " " + props.surName2})
                          : i18n.t("common:text.contractation_change_owner_question")
                        }
                      </Typography>
                      <Field
                        name="changeOwner"
                        component={RadioGroupField}
                        radioElements={
                          [
                            {label: i18n.t("common:text.generic_yes"), val: "changeOwner", style: {width: "25%"}},
                            {label: i18n.t("common:text.generic_no"), val: "dontChangeOwner", style: {width: "25%"}}
                          ]
                        }
                        row
                      />
                    </Paper>
                  </Grid>
                  }
                  {_.get(
                    Settings,
                    props.gas ? "newDualContract.commsLanguage" : "newContract.commsLanguage",
                    true
                  ) &&
                    <Grid item xs={12} md={6}>
                      <Field name="commsLanguage" component={LangSelectField} />
                    </Grid>
                  }
                  <Grid item xs={12} style={{paddingBottom: 0}}>
                    <Divider style={{marginBottom: 10}}/>
                    <Field
                      name="sameInvoiceAddress"
                      label={i18n.t('common:text.contractation_same_address')}
                      component={CheckboxField}
                    />
                    {!values.sameInvoiceAddress && (
                      <Grid item xs={12}>
                        <Field
                          name="invoiceAddress"
                          component={AddressField}
                          label={i18n.t('common:text.contractation_invoicing_address')}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{marginBottom: 20}}/>
                    <Typography variant="h6">
                      {i18n.t('common:text.contractation_doc_photos', 
                        {doc: company ? i18n.t('common:text.contractation_doc_photo_cif') : "DNI"})
                      }
                    </Typography>
                    <Paper style={{padding: 15}} variant="outlined">
                      {company ? 
                      <Field
                        name="nifEmpresa"
                        component={FileUploadField}
                        min={1}
                        label={i18n.t('common:text.contractation_nif_doc') + "*"}
                        hint={
                          i18n.t('common:text.contractation_doc_invoice_helper',
                          {doc: i18n.t('common:text.contractation_doc_photo_cif')})
                        }
                        anotherLabel={i18n.t('common:text.contractation_nif_add')}
                        removeLabel={i18n.t('common:text.remove')}
                      />
                      :
                      <Field
                        name="nifFaceup"
                        component={FileUploadField}
                        min={1}
                        max={2}
                        label={i18n.t('common:text.contractation_dni_front') + "*"}
                        hint={i18n.t('common:text.contractation_doc_invoice_helper', {doc: 'DNI'})}
                        anotherLabel={i18n.t('common:text.contractation_dni_back')}
                        removeLabel={i18n.t('common:text.remove')}
                      />  
                    }
                    </Paper>
                  </Grid>
                </Grid>
              </Box>

              <div style={{ marginTop: 25 }}>
                <Button
                  variant={'text'}
                  onClick={() => handleGoBack(values)}
                  style={{ marginRight: 12 }}
                  disabled={submitting}
                >
                  {i18n.t('common:text.contractation_previous')}
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? <CircularProgress size={25} /> : null}
                  {i18n.t('common:text.contractation_next')}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutYou);
